import React from 'react'
import Helmet from 'react-helmet'
import LanguageSummary from '../components/language-summary'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const Translations = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const pageTitle = `Translations`;
  const languages = data.allContentfulLanguage.edges.map(({ node }) => node);
  const languageItems = languages.map(l => {
    return <LanguageSummary key={l.id} language={l} />;
  });

  return (
    <Layout>
      <div className="post">
        <Helmet title={`${pageTitle} | ${siteTitle}`} />
        <h2 className="title">{pageTitle}</h2>
        <ul>
          {languageItems}
        </ul>
      </div>
    </Layout>
  );
}

export default Translations

export const languagesQuery = graphql`
  query LanguagesQuery {
    site {
      ...siteMeta
    }
    allContentfulLanguage(sort: { fields: [ordinal] }) {
      edges {
        node {
          ...languageSummary
        }
      }
    }
  }`;
