import React from 'react'
import Link from 'gatsby-link'

const LanguageSummary = ({ language }) => {
  return (
    <Link className="post-summary language-summary" to={language.fields.path}>
      <div className="post-summary-title">
        <h2>{language.title}</h2>
      </div>
    </Link>
  );
}

export default LanguageSummary
